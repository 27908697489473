// Basic reset for all
// ------------------------------

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

* {
  box-sizing: border-box;
}

html,
body {
  font-family: $base-font;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: unset !important;
}

html {
  overflow-x: hidden;
  -mobile-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: $base-font-size;
  line-height: 1;
  -webkit-text-size-adjust: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
p,
small {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

img {
  width: 100%;
  max-width: 100%;
}

figure {
  margin: 0;
}

input,
textarea,
select {
  outline: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

svg-icon {
  line-height: 0;
}

a:hover {
  text-decoration: none;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
