// Headlines
@mixin headline-h1-xl($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(22px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }

  @include mq('desktop') {
    font-size: rem(28px);
  }
}

@mixin headline-h1($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(22px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }

  @include mq('desktop') {
    font-size: rem(28px);
  }
}

@mixin headline-h2($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(22px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }

  @include mq('desktop') {
    font-size: rem(28px);
  }
}

@mixin nav($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(18px);
  line-height: 1.2;
}

@mixin headline-h3($weight: 500, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(21px);
  }

  @include mq('desktop') {
    font-size: rem(24px);
  }
}

@mixin headline-h4($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(18px);
  }
}

@mixin title($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(20px);
  }
}

@mixin subtitle($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(18px);
  }
}

@mixin paragraph($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(16px);
  }
}

@mixin buttonMedium($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-size: rem(14px);
  line-height: 1.6;
}

@mixin small($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(10px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(12px);
  }
}

@mixin button($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(18px);
  }
}

@mixin buttonSmall($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-size: rem(10px);
  line-height: 1.2;
}

@mixin menu($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;
}

// Rating Star
@mixin star() {
  font-size: rem(22px);
}

@mixin star-small() {
  font-size: rem(20px);
}

@mixin star-big() {
  font-size: rem(30px);
}

@mixin star-medium() {
  font-size: rem(50px);
}

@mixin star-bigger() {
  font-size: rem(80px);
}

//cart
@mixin cartItems($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(15px);
  line-height: 1.2;
}

@mixin cartItemProduct($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(12px);
  line-height: 1.2;
}

@mixin cartItemPrice($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;
}

@mixin cartTotal($weight: 600, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(18px);
  line-height: 1.2;
}

//checkout

@mixin checkoutTabs($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(28px);
  }
}

@mixin checkoutLabel($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(16px);
  }
}

@mixin input($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(16px);
  }
}

@mixin menu-title($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-size: rem(16px);
  line-height: 1.2;
}

@mixin home-small($weight: 600, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-size: rem(10px);
  line-height: 1.2;
}

@mixin menu-mobile-label($weight: 500, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(16px);
  }
}

// promotions

@mixin promotionParagraph($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(12px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(16px);
  }
}

@mixin promotionHeadline-h1($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(28px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(44px);
  }
}

@mixin promotionHeadline-h3($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(24px);
  }
}

@mixin promotionInfosTitle($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(18px);
  }
}

@mixin checkoutSpan($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(12px);
  line-height: 1.2;
  color: color(gray600);

  @include mq('tablet') {
    font-size: rem(16px);
  }
}

@mixin checkoutTotal($weight: 700, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(20px);
  line-height: 1.2;
}

@mixin checkoutEditTextButton($font: $second-font) {
  color: color(brandColor500);
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

@mixin smallParagraphClub($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: calc(13px * 1.5);

  @include mq('tablet') {
    font-size: rem(14px);
  }
}

@mixin headlineClub($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(20px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }
}

@mixin smallParagraphPayments($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(14px);
  }
}

@mixin title-benefits($weight: 500, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(20px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(24px);
  }
}

@mixin subtitleSignature($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(18px);
  }
}

@mixin headline-club($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(20px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }
}

@mixin promotionInviteTitle($weight: 500, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(24px);
  }
}

@mixin wishlist-description($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: rem(17px);

  @include mq('tablet') {
    font-size: rem(13px);
  }
}

@mixin card($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(12px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(14px);
  }
}

@mixin mobile-text-paragraph($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: rem(17px);
  color: color('gray600');
}

@mixin desktop-text-paragraph($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: rem(18px);
  color: color('gray600');
}
