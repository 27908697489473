/* You can add global styles to this file, and also import other style files */

@import './styles/index.scss';

@import 'variables';
@import 'typography';

@import '~@ng-select/ng-select/themes/default.theme.css';

h1 {
  @include headline-h1();
}

h2 {
  @include headline-h2();
}

h3 {
  @include headline-h3();
}

h4 {
  @include headline-h4();
}

p {
  @include paragraph();

  &.title {
    @include title();
  }

  &.subtitle {
    @include subtitle();
  }
}

small {
  @include small();

  &.error {
    color: color(danger);
  }
}

commons-button {
  @include button();
}

commons-input {
  @include paragraph();
}

.container {
  padding: 0 rem(15px);

  @include mq('tablet') {
    padding: 0 rem(16px);
  }
}

.mens-container {
  max-width: 1216px;
  margin: 0 auto;
}

.home-button {
  @include paragraph(600);
  background: color('brand500');
  min-width: 125px;
  max-width: 280px;
  width: fit-content;
  text-align: center;
  color: #ffffff;
  height: 50px;
  width: 100%;

  &:disabled {
    background-color: color('gray-100');
  }
}

.modal-dialog {
  max-width: 590px;

  &--login {
    max-width: 345px;
  }
}

#home,
#last-editions,
#testimonials {
  .slick-list {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-dots {
    bottom: rem(30px) !important;
    border-radius: rem(18px) !important;
    display: flex;
    left: 50%;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: auto;

    li {
      align-items: center;
      box-sizing: content-box;
      display: flex;
      height: rem(27px);
      justify-content: center;
      margin: 0;
      padding: 0;
      vertical-align: top;
      width: rem(27px);
    }

    .slick-active {
      button {
        background: color('black') !important;
        border-radius: rem(18px);
        width: rem(18px);
        height: rem(40px);
      }
    }

    button {
      background: #ffdfee !important;
      border: 0;
      border-style: solid;
      border-radius: 50%;
      color: transparent;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: rem(18px);
      line-height: rem(0px);
      outline: none;
      padding: 0;
      transition: 0.15s ease;
      width: rem(18px);

      &:before {
        display: none;
      }
    }
  }
}

#last-editions {
  .slick-dots {
    bottom: rem(-30px) !important;
  }
}

.jewel-modal,
.order-modal {
  .modal-content {
    border-radius: 16px;
  }
}

.review-received-modal {
  max-width: 345px;
}

ngb-modal-window {
  z-index: 100000000 !important;
}

.invisible {
  display: none;
}

.modal-login {
  max-width: 280px;
  border: 1px solid color('brandColor500');
  margin: 44px 0 auto auto;
  border-radius: 6px;

  @include mq('desktop') {
    margin-right: calc(50% - 62.5rem);
  }

  @include mq('laptop') {
    margin-top: 59px;
    margin-right: calc(50% - 62.5rem);
  }
}

.modal-submenu {
  max-width: 280px;
  margin: 44px 0 auto auto;
  border-radius: 6px;
  height: calc(100vh - 44px);
  background: color('white');

  @media screen and (max-width: 768px) {
    .modal-content {
      border: none !important;
    }
  }

  @include mq('tablet') {
    border: 1px solid color('brandColor500');
    height: auto;
    margin-right: 15px;
  }

  @media screen and (min-width: 1280px) {
    margin-right: calc(50% - 62.5rem);
  }

  // @include mq('desktop'){
  //   margin-right: calc(50% - 62.5rem);
  // }

  @include mq('laptop') {
    margin-top: 59px;
    // margin-right: calc(50% - 62.5rem);
  }
}

form > .form-group {
  margin-bottom: 1rem;
}

@media (min-width: 480px) {
  .modal-open {
    overflow: unset !important;
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-small {
  max-width: rem(410px);
  height: rem(415px);
  margin: 0 auto;
}

.modal-cart {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  width: 100%;
  max-width: rem(480px);

  @include mq('lg-mobile') {
    max-width: rem(400px);
  }
}

.rating-modal {
  max-width: rem(600px);
  border-radius: rem(8px);
  max-height: 90vh;
  overflow-y: auto;
}

.modal-backdrop {
  z-index: 10000 !important;
}

.mens-accordion {
  display: flex;
  flex-direction: column;
  row-gap: rem(8px);
  padding: 0 rem(15px);
  flex: 1;

  &.options {
    flex: 3;
  }

  .tab {
    background: color('white');
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(50px);
    min-width: 280px;
    color: color('gray700');
    border: 1px solid color('gray100') !important;
    box-sizing: border-box;

    &--active {
      border: 1px solid color('brandColor500') !important;
    }

    &--active-dark {
      border: 1px solid color('gray600');
    }

    &--expandable {
      flex-direction: column;
      height: fit-content;
    }

    &--header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--clickable {
      cursor: pointer;
    }

    &--expanded {
      flex-direction: column;
      height: auto !important;
      row-gap: rem(30px);
      align-items: flex-start;
    }

    .tab-option {
      display: flex;
      width: 100%;
      align-items: center;
      column-gap: rem(10px);

      &__icon {
        flex: 1;
        text-align: center;
      }

      &__chevron {
        flex: 1;
        text-align: right;
      }

      &__title {
        flex: 3;
        text-align: left;
      }
    }
  }

  .actions {
    &--clickable {
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(50px);
    width: 100%;
    column-gap: rem(10px);

    &__left {
      display: flex;
      column-gap: rem(25px);
      align-items: center;
      flex: 1;

      img {
        flex: 1;
        height: auto;
      }

      &__text {
        flex: 2;
        display: flex;
        flex-direction: column;
        row-gap: rem(8px);
        text-align: left;
      }
    }

    &__right {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        display: flex;
        flex-direction: column;
        row-gap: rem(16px);
      }
    }
  }
}

.subscription-carousel {
  .carousel-arrow {
    top: 270px !important;
  }

  .carousel-dots {
    top: 270px !important;
  }

  .carousel-container {
    padding-bottom: 10px;
  }
}

.mensmarket-modal-form {
  color: color('gray700');
  padding: rem(24px) rem(35px);

  h1 {
    text-align: center;
  }

  &__form {
    margin: rem(20px) 0;

    &__row {
      margin-bottom: rem(10px);
      color: color('gray700');

      &__divided {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        @include mq('lg-mobile') {
          flex-direction: row !important;
          column-gap: 30px;
        }

        > div {
          flex: 1;
        }
      }
    }

    &__label {
      @include paragraph();
    }

    &__buttons {
      display: flex;
      margin-top: 30px;
      flex-direction: column;
      row-gap: 16px;

      @include mq('lg-mobile') {
        flex-direction: row !important;
        column-gap: 30px;
      }

      commons-button {
        flex: 1;
      }
    }
  }
}

.plan-renewal {
  max-width: 450px;
  margin: 0 auto;
}

.plan-recurrence-cancel {
  max-width: 500px;
}

.error-modal {
  width: 345px;
  padding: 32px 20px;
  @include mq('laptop') {
    width: 424px;
  }
}

.billing-history {
  max-width: 750px;
}

.featured-products__button {
  .button {
    width: rem(345px);
  }
}

.bold {
  font-weight: 600 !important;
}

.brands-slider {
  .slick-arrow {
    width: rem(10px) !important;

    @include mq('tablet') {
      width: rem(40px) !important;
      height: rem(40px) !important;
    }
  }

  .slick-prev {
    background-image: url('assets/icons/button-chevron-left.svg') !important;

    @include mq('tablet') {
      background-image: url('assets/icons/arrow-blue-prev.svg') !important;
    }
  }

  .slick-next {
    background-image: url('assets/icons/button-chevron-right.svg') !important;

    @include mq('tablet') {
      background-image: url('assets/icons/arrow-blue-next.svg') !important;
    }
  }
}

.clean-tile {
  max-width: 100%;

  .tile {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
  }
}

.home-carousel {
  .tile {
    box-shadow: none !important;
  }

  .item {
    padding: 0 !important;
  }

  .dots {
    margin-top: rem(30px);
    white-space: nowrap;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(50% - 34px);
    bottom: 15px;

    li {
      display: inline-block;
      min-width: 18px;
      height: 18px;
      margin: 0 rem(8px);
      background: color('brandColor300');
      border-radius: rem(18px);
      cursor: pointer;

      &.active {
        border-radius: rem(16px);
        width: 15px;
        height: 40px;
        background: color('brandColor500');
      }
    }
  }

  .leftRs {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 1000;
    background: color('white');

    &:focus {
      outline: none;
    }
  }

  .rightRs {
    z-index: 1000;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    right: 0;
    background: color('white');

    &:focus {
      outline: none;
    }
  }
}

.testimonials-carousel {
  .tile {
    box-shadow: none !important;
    width: 90%;
    margin: 0 auto;
  }

  .item {
    padding: 0 !important;
  }

  .dots {
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 34px);
    bottom: 15px;
    margin-top: rem(30px);

    li {
      display: inline-block;
      min-width: 18px;
      height: 18px;
      margin: 0 rem(4px);
      background: color('brandColor300');
      border-radius: rem(18px);
      cursor: pointer;

      @include mq('tablet') {
        margin: 0 rem(8px) !important;
      }

      &.active {
        border-radius: rem(16px);
        width: 15px;
        height: 40px;
        background: color('brandColor500');
      }
    }
  }

  .leftRs {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 1000;
    background: color('white');

    &:focus {
      outline: none;
    }
  }

  .rightRs {
    z-index: 1000;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    right: 0;
    background: color('white');

    &:focus {
      outline: none;
    }
  }
}

.editions-carousel {
  .tile {
    box-shadow: none !important;
    margin: 0 20px;
  }

  .item {
    padding: 0 !important;
  }

  .leftRs {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 1000;
    background: color('white');

    @include mq('tablet') {
      left: 20px;
    }

    &:focus {
      outline: none;
    }
  }

  .rightRs {
    z-index: 1000;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    right: 0;
    background: color('white');

    &:focus {
      outline: none;
    }
  }
}

.box-slider-banner {
  height: calc(100vh - 60px);

  @include mq('laptop') {
    height: calc(100vh - 130px);
  }

  &__box {
    height: calc(100vh - 130px) !important;
    margin: 0;
  }

  &__box-mobile {
    height: calc(100vh - 60px) !important;
    margin: 0;
  }

  &__item {
    height: calc(100vh - 60px);

    // @include mq('laptop') {
    //   height: calc(100vh - 130px);
    // }
  }

  &__image-title {
    width: 50%;
    max-width: rem(250px);
    margin-top: 5vh;
  }

  &__image-title-larger {
    width: 80%;
    max-width: rem(550px);
    margin-top: 16vh;

    @include mq('laptop') {
      margin-top: 0;
    }
  }

  &__image-title-centered {
    width: 90%;
    margin-top: 5vh;
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    background-position: top;

    @include mq('laptop') {
      background-position: center;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    height: calc(100vh - 59px);
    width: 100%;
    align-items: flex-start;

    @include mq('laptop') {
      align-items: center;
      max-width: 50%;
    }
  }

  &__content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(20px);
    min-width: 100%;

    @include mq('laptop') {
      min-width: 50vw;
      margin-left: rem(50px);
    }
  }

  &__text {
    // @include headline-h1-xl();
    text-align: center;
    max-width: 80%;
    color: color('black');
    text-align: center;
    margin-top: rem(7px);

    &--title {
      margin-top: rem(25px);
    }

    @include mq('laptop') {
      margin-top: rem(7px);
    }

    &__highlight {
      color: #e99ce2;
    }

    &__subtitle {
      text-align: center;
      margin-top: rem(20px);
      margin-bottom: rem(30px);
      max-width: 360px;
      line-height: rem(16px);
      @include subtitle(600, 'Open sans');

      @include mq('laptop') {
        @include subtitle(normal, 'Open sans');
      }

      &__white {
        color: color('brandColor100');
      }
    }

    &__subtitle-centered {
      text-align: center;
      margin-top: rem(20px);
      margin-bottom: rem(30px);
      max-width: rem(360px);
      line-height: rem(16px);
      @include subtitle(600, 'Open sans');

      @include mq('laptop') {
        @include subtitle(normal, 'Open sans');
        max-width: rem(600px);
      }

      &__white {
        color: color('brandColor100');
      }
    }

    &__background {
      background: color('brandColor300');
      color: #6c287f;
    }
  }
}

.subscription-carousel {
  .ngucarousel {
    overflow: visible !important;
  }

  .tile {
    box-shadow: none !important;
    margin: 0 rem(10px);
  }

  .item {
    padding: 0 !important;
  }

  .dots {
    margin-top: rem(30px);
    white-space: nowrap;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      display: inline-block;
      min-width: rem(14px);
      height: rem(14px);
      margin: 0 rem(2.5px);
      background: color('brandColor300');
      border-radius: rem(18px);
      cursor: pointer;

      @include mq('lg-mobile') {
        min-width: rem(18px);
        height: rem(18px);
        margin: 0 rem(8px);
      }

      &.active {
        border-radius: rem(16px);
        width: 15px;
        height: 40px;
        background: color('brandColor500');
      }
    }
  }

  .leftRs {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: rem(-320px);
    width: rem(50px);
    height: rem(50px);
    left: 0;
    z-index: 1000;

    @include mq('tablet') {
      bottom: rem(-350px);
    }

    &:focus {
      outline: none;
    }
  }

  .rightRs {
    z-index: 1000;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: rem(-320px);
    width: rem(50px);
    height: rem(50px);
    right: 0;

    @include mq('tablet') {
      bottom: rem(-350px);
    }

    &:focus {
      outline: none;
    }
  }
}

.hidden {
  display: none;
}

.shop-home-carousel {
  .item {
    padding: 0;
  }

  .dots {
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 25px;
    margin-top: rem(30px);
    white-space: nowrap;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(50% - 34px);
    bottom: 15px;
    min-height: rem(30px) !important;

    li {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: rem(4px) rem(8px);
      background: color('gray400');
      border-radius: rem(18px);
      cursor: pointer;

      &.active {
        border-radius: rem(16px);
        width: 15px;
        height: 15px;
        background: transparent;
        border: 2px solid color('black');
      }
    }
  }
}

.shop-benefits-carousel {
  .tile {
    box-shadow: none !important;
    display: flex;
    align-items: center;
    column-gap: rem(30px);
  }

  .leftRs {
    position: absolute;
    top: 50%;
  }

  .rightRs {
    position: absolute;
    top: 50%;
    right: 0;
  }
}

.shop-vertical-carousel {
  position: initial !important;

  .ngucarousel {
    position: initial !important;
    height: 520px !important;

    @include mq('tablet') {
      height: 400px !important;
    }

    @include mq('laptop') {
      height: 520px !important;
    }
  }

  .item {
    padding: rem(10px) 0 !important;
  }

  .tile {
    box-shadow: none !important;
  }

  .rightRs {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .leftRs {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

.shop-brands-slider {
  .tile {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
  }

  .ngucarousel-items {
    align-items: center;
    margin-left: rem(-50px);
    padding-bottom: rem(15px);
  }

  .ngucarousel {
    position: initial !important;
    width: 90%;
    margin: 0 auto;
  }

  .leftRs {
    position: absolute;
    top: 30%;
    left: 0;
  }

  .rightRs {
    position: absolute;
    top: 30%;
    right: 0;
  }
}

button:focus {
  outline: none !important;
}

.center {
  text-align: center;
}

a:focus-visible {
  outline: none !important;
}

ngb-tooltip-window {
  // top: -15px !important;

  .tooltip-inner {
    @include small();
    background-color: color('gray700');
    display: block;
    text-align: center;
    color: #fff;
    padding: rem(7px) rem(10px);
    border-radius: rem(8px);
    // top: 0;
    // &::before{
    //   content: none;
    // }
    // &::after{
    //   content: '';
    //   display: block;
    //   width: 15px;
    //   height: 0;
    //   position: absolute;
    //   border-left: 7px solid transparent;
    //   border-right: 7px solid transparent;
    //   border-top: 12px solid #343434;
    //   top: 23px;
    //   left: 38px;
    //   @include mq('tablet'){
    //     border-left: 10px solid transparent;
    //     border-right: 10px solid transparent;
    //     border-bottom: 17px solid color('gray700');
    //     border-top: 0;
    //     top: -15px;
    //     left: 47px;

    //   }
    // }
  }

  .arrow {
    border-top-color: color('gray700');
  }
}

.action-copied {
  @include small(bold, 'Montserrat');
  background: color('white');
  border: 1px solid color('green700');
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: rem(15px);
  animation: 4s fadeinout;
  max-width: 40%;
  height: rem(45px);
  color: color('green700');
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  right: 0;
  min-width: 218px;

  img {
    max-width: rem(12px);
  }
}

#launcher-frame {
  z-index: 100000 !important;
}

.modal-menu-mobile {
  height: calc(100vh - 44px);
  position: fixed;
  left: 0;
  top: 44px;

  @include mq('laptop') {
    display: none;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.modal-dialog.paywall-modal {
  max-width: 650px !important;
}

.modal-dialog.ad-modal {
  max-width: rem(450px) !important;
  margin: 0 auto;

  @include mq('tablet') {
    max-width: rem(800px) !important;
  }

  .modal-content {
    border: none !important;
    border-radius: rem(8px);
  }
}

.ad-modal-backdrop {
  background-color: color('white') !important;
}

.glambox-newsletter {
  commons-input {
    .input {
      max-height: unset !important;
      height: 100% !important;
    }
  }
}

.editions-container {
  .slick-prev {
    background: url('assets/icons/arrow-gray-left.svg') no-repeat !important;
    left: 0 !important;

    &::before {
      content: '' !important;
    }
  }

  .slick-next {
    background: url('assets/icons/arrow-gray-right.svg') no-repeat !important;
    right: 0 !important;

    &::before {
      content: '' !important;
    }
  }

  .slick-disabled {
    opacity: 0.4;
  }
}

.subs-container {
  .slick-prev {
    background: url('assets/icons/arrow-gray-left.svg') no-repeat !important;
    left: 0 !important;

    &::before {
      content: '' !important;
    }
  }

  .slick-next {
    background: url('assets/icons/arrow-gray-right.svg') no-repeat !important;
    right: 0 !important;

    &::before {
      content: '' !important;
    }
  }
}

.toast-warning {
  background-color: #fff3d4;
  border: 1px solid #ffc427;
  border-radius: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.toast-container .ngx-toastr {
  color: color('gray700');
  background-image: none !important;
}

.toast-close-button {
  position: relative;
  right: 0.6em;
  top: 0.3em;
  float: right;
  font-size: 30px;
  color: color('starYellow');
  text-shadow: color('starYellow');

  @media screen and (max-width: 1086px) {
    display: none;
  }
}

.toast-top-full-width {
  top: 120px;

  .toast-warning {
    border-radius: 8px;
  }
}

.mens-info {
  &__brand-carousel__slider__box {
    .tile {
      box-shadow: unset !important;
    }
  }
}

.signatures-info {
  &__content {
    &__buttons {
      &__button {
        &__text-bold {
          font-weight: 600;
        }

        &__link {
          font-weight: 600 !important;
          color: color('brandColor500') !important;
          text-decoration: underline !important;
        }
      }
    }
  }
}

.faq {
  &__container {
    &__right {
      &__faq {
        &__subtitle {
          &__text {
            &__link {
              color: color('brandColor500') !important;
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}

.testimonials {
  &__baloon-left {
    border-bottom-left-radius: 0 !important;

    &::before {
      content: '';
      display: inline-block;
      background: url('assets/icons/baloon-left.svg');
      background-repeat: no-repeat;
      width: rem(21px);
      height: rem(19px);
      position: absolute;
      bottom: -3px;
      left: -21px;
    }
  }

  &__baloon-right {
    border-bottom-right-radius: 0 !important;

    &::after {
      content: '';
      display: inline-block;
      background: url('assets/icons/baloon-right.svg');
      background-repeat: no-repeat;
      width: rem(21px);
      height: rem(19px);
      position: absolute;
      bottom: -3px;
      right: -21px;
    }
  }
}

.how-it-works__content-box__item__text__link {
  color: color('brandColor500') !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.modal-dialog.create-favorite-modal {
  max-width: 345px !important;
  margin: 0 auto;

  .modal-content {
    border-radius: rem(8px) !important;
  }

  @include mq('tablet') {
    max-width: 424px !important;
  }
}

.modal-dialog.modal-options-favorite {
  max-width: 313px !important;
  margin: 0 auto;

  .modal-content {
    border-radius: rem(8px) !important;
  }

  @include mq('tablet') {
    max-width: 424px !important;
  }
}

@keyframes topdown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.modal-dialog.modal-product-back-in-stock {
  margin: 0 auto;
  padding: 0 rem(16px) 0 rem(16px);

  @include mq('tablet') {
    max-width: 424px !important;
  }
}
