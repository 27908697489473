@use 'sass:math';

// Media Queries
// ----------------------------------------------------------

$mq: (
  'sm-mobile': 320px,
  'lg-mobile': 480px,
  'tablet': 768px,
  'laptop': 1024px,
  'lg-laptop': 1200px,
  'desktop': 1366px,
  'infinity': 1440px
);

// Guide Colors
// ----------------------------------------------------------

$colors: (
  'transparent': transparent,
  'white': #fff,
  'black': #000,
  'purple': #8540f5,
  'brandColor50': #e5f4f8,
  'brandColor100': #faf0d4,
  'brandColor150': #cceef8,
  'brandColor200': #80c8dc,
  'brandColor300': #00aadc,
  'brandColor500': #0091b9,
  'gray00': #f7f7f7,
  'gray15': #f1f1f1,
  'gray25': #f5f5f5,
  'gray30': #eaeaea,
  'gray40': #eaebec,
  'gray50': #e2e2e2,
  'gray75': #eeeeee,
  'gray85': #e1e1e1,
  'gray100': #c4c4c4,
  'gray200': #b4b4b4,
  'gray250': #a9a9a9,
  'gray300': #a2a2a2,
  'gray400': #858383,
  'gray450': #6c757d,
  'gray500': #656565,
  'gray600': #464a52,
  'gray700': #343434,
  'gray800': #2d2c2c,
  'google400': #d7282a,
  'facebook400': #3b5998,
  'pink100': #fae1e3,
  'green200': #eafef1,
  'green250': #e5f3eb,
  'green450': #07de1d,
  'green500': #0eb551,
  'green700': #008733,
  'danger': #dc3545,
  'orange': #ff9752,
  'starYellow': #ffc427,
  'warning200': #fff3d4,
  'warning300': #ffe8ab
);

$shadows: (
  'card': 0 0 5px rgba(0, 0, 0, 20%),
  'checkout': 4px 4px 25px rgba(0, 0, 0, 0.1)
);

// Functions
// ----------------------------------------------------------

@mixin mq($mq-value) {
  @media (min-width: map-get($mq, $mq-value)) {
    @content;
  }
}

@function shadow($shadow-value) {
  @return map-get($shadows, $shadow-value);
}

@function color($color-value) {
  @return map-get($colors, $color-value);
}

@function rem($size) {
  @return math.div($size, 10px) * 1rem;
}

// Default variables
// ----------------------------------------------------------

$base-font-size: 62.5%;
$base-font: 'Open Sans', sans-serif;
$second-font: 'Montserrat', sans-serif;
